<template>
	<div class="ranking-configuration">
		<div class="ranking-configuration__description p-0 mb-4 mt-2">
			<TextPlay
				weight="bold"
				color="var(--fontcolor)"
				:text="$t('gamification.ranking_configuration')"
			/>
			<TextPlay
				color="var(--text-color)"
				:text="$t('gamification.ranking_configuration_tip')"
			/>
		</div>

		<CoinNameConfig
			@input="coinName = $event"
			:coinName="coinName"
			:isLoading="triggersLoading"
		/>

		<div class="ranking-configuration__triggers mt-4 mb-4 col-12 col-md-9 p-0">
			<TextPlay
				class="font-color"
				weight="bold"
				:text="$t('gamification.set_triggers')"
			/>
			<TextPlay
				v-html="$t('gamification.triggers_description')"
				color="var(--text-color)"
			/>
		</div>

		<div class="ranking-configuration__config mt-4">
			<TriggersGamificationConfig
				:isLoading="triggersLoading"
				@inputChanged="triggers = $event"
				:triggers="triggers"
			/>

			<ButtonPlay
				class="mt-4"
				type="normal"
				@click="saveRankingConfiguration"
				iconRight="arrow-right-1"
				:text="$t('gamification.save_and_activate_ranking')"
			/>
		</div>

		<FeedbackModalPlay
			@confirm="$emit('goNext')"
			@deny="$bvModal.hide('feedbackModal')"
			:isLoading="saveLoading"
			:confirmBtnText="feedbackConfirmBtnText"
			confirmBtnIcon="buy-crypto"
			confirmBtnIconType="bold"
			id="feedbackModal"
			:status="feedbackStatus"
			:title="feedbackTitle"
			:content="feedbackContent"
		/>
	</div>
</template>

<script>
import TriggersGamificationConfig from "@/components/TriggersGamificationConfig.vue";
import CoinNameConfig from "@/components/CoinNameConfig.vue";
import notify from "@/services/libs/notificacao";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		TriggersGamificationConfig,
		CoinNameConfig,
	},
	data() {
		return {
			coinName: "",
			triggers: {},
			triggersLoading: true,
			saveLoading: true,
			feedbackStatus: "",
			feedbackConfirmBtnText: "",
			feedbackTitle: "",
			feedbackContent: "",
		};
	},
	methods: {
		...mapActions({
			fetchRankingConfig: "gamification/fetchRankingConfig",
			setRankingConfig: "gamification/setRankingConfig",
		}),
		async validateFields() {
			await this.$validator.validateAll();
			let isValid = this.$validator.errors.items.length === 0;

			const coinNameErrors = this.$validator.errors.items.find(
				error => error.field === this.$t("gamification.medal_name")
			);
			if (coinNameErrors) {
				notify("erro", this.$t("gamification.you_need_to_give_a_coin_name"));
				return false;
			}

			if (!this.triggers || Object.keys(this.triggers).length === 0) {
				notify("erro", this.$t("gamification.you_need_to_set_trigger"));
				return false;
			}

			if (!Object.values(this.triggers).some(value => value)) {
				notify("erro", this.$t("gamification.you_need_to_set_trigger"));
				return false;
			}

			if (!isValid) {
				notify("erro", this.$t("gamification.ranking_validation_error"));
				return false;
			}

			return true;
		},
		async saveRankingConfiguration() {
			const isValid = await this.validateFields();

			if (isValid) {
				this.saveLoading = true;
				this.$bvModal.show("feedbackModal");

				let data = {
					coin_name: this.coinName,
					points: this.triggers,
				};

				try {
					await this.setRankingConfig(data);
					this.feedbackStatus = "success";
					this.feedbackConfirmBtnText = this.$t("gamification.to_config_medals");
					this.feedbackTitle = this.$t("gamification.ranking_configuration_saved");
					this.feedbackContent = this.$t("gamification.ranking_configuration_saved_desc");
				} catch (error) {
					this.feedbackStatus = "error";
					this.feedbackConfirmBtnText = "";
					this.feedbackTitle = this.$t("gamification.error_to_save_ranking");
					this.feedbackContent = this.$t("gamification.error_to_save_ranking_desc");
				}

				this.saveLoading = false;
			}
		},
		updateRankingData(rankingConfig) {
			this.coinName = rankingConfig.coin_name;
			this.triggers = rankingConfig.points;
		},
	},
	computed: {
		...mapGetters({ rankingConfig: "gamification/getRankingConfig" }),
	},
	watch: {
		rankingConfig(newRankingConfig) {
			if (newRankingConfig) {
				this.updateRankingData(newRankingConfig);
			}
		},
	},
	async mounted() {
		this.triggersLoading = true;
		await this.fetchRankingConfig();
		this.triggersLoading = false;
	},
};
</script>

<style lang="scss" scoped>
.ranking-configuration {
	.font-color {
		color: var(--fontcolor);
	}
}
</style>
