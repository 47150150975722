<template>
	<div class="triggers-container">
		<InputDescribed
			v-for="(input, index) in inputDescribedList"
			:skeleton="isLoading"
			:key="index"
			inputWidth="100px"
			:id="input.id"
			:name="input.title"
			:validation="{ required: !isTriggerSet, numeric: true, min: 1 }"
			:preventNonNumeric="true"
			:preventDots="true"
			:preventHyphens="true"
			:preventLetters="true"
			:preventSpaces="true"
			:placeholder="$t('gamification.zero_points')"
			class="mt-4"
			type="number"
			min="1"
			:title="input.title"
			:description="input.description"
			:value="input.value"
			@input="handleInputChange(index, $event)"
		/>
	</div>
</template>

<script>
import InputDescribed from "@/components/InputDescribed.vue";

export default {
	components: {
		InputDescribed,
	},
	props: {
		triggers: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputDescribedList: [
				{
					value: "",
					id: "first_login",
					title: this.$t("gamification.first_login"),
					description: this.$t("gamification.first_login_desc"),
				},
				{
					value: "",
					id: "daily_access",
					title: this.$t("gamification.daily_access"),
					description: this.$t("gamification.daily_access_desc"),
				},
				{
					value: "",
					id: "complete_lesson",
					title: this.$t("gamification.complete_lesson"),
					description: this.$t("gamification.complete_lesson_desc"),
				},
				{
					value: "",
					id: "rating_lesson",
					title: this.$t("gamification.rating_lesson"),
					description: this.$t("gamification.rating_lesson_desc"),
				},
				{
					value: "",
					id: "comment_lesson",
					title: this.$t("gamification.comment_lesson"),
					description: this.$t("gamification.comment_lesson_desc"),
				},
				{
					value: "",
					id: "complete_quiz",
					title: this.$t("gamification.complete_quiz"),
					description: this.$t("gamification.complete_quiz_desc"),
				},
				{
					value: "",
					id: "complete_module",
					title: this.$t("gamification.complete_module"),
					description: this.$t("gamification.complete_module_desc"),
				},
				{
					value: "",
					id: "complete_course",
					title: this.$t("gamification.complete_course"),
					description: this.$t("gamification.complete_course_desc"),
				},
				{
					value: "",
					id: "get_certificate",
					title: this.$t("gamification.get_certificate"),
					description: this.$t("gamification.get_certificate_desc"),
				},
			],
		};
	},
	computed: {
		sanitizedInputData() {
			return this.inputDescribedList.reduce((acc, input) => {
				acc[input.id] = input.value;
				return acc;
			}, {});
		},
		isTriggerSet() {
			return Object.values(this.triggers).some(value => value);
		},
	},
	methods: {
		handleInputChange(index, newValue) {
			this.inputDescribedList[index].value = newValue;
			this.$emit("inputChanged", this.sanitizedInputData);
		},
		putTriggersPropsAtInputs() {
			this.inputDescribedList.forEach(input => {
				if (input.id in this.triggers) {
					input.value = this.triggers[input.id];
				}
			});
		},
	},
	updated() {
		this.putTriggersPropsAtInputs();
	},
};
</script>

<style lang="scss" scoped>
.skeleton-triggers {
	min-height: 500px;
	max-width: 400px;
	width: auto;
}
</style>
