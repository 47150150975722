<template>
	<div class="medal-configuration d-flex mb-4 p-0">
		<EmblemInput
			:emblem="emblem"
			@input="handleEmblemInput"
			:skeleton="skeleton"
			@delete="handleDelete"
		/>

		<InputFormPlay
			class="col-12 col-md-4"
			:placeholder="$t('gamification.medal_name')"
			:label="$t('gamification.give_medal_name')"
			:description="$t('gamification.medal_name_tip')"
			:validation="{ required: true, max: 30 }"
			maxlength="30"
			@input="handleNameInput"
			:value="name"
			:skeleton="skeleton"
		/>

		<InputFormPlay
			class="col-12 col-md-4"
			:placeholder="$t('gamification.points_quantity')"
			:label="$t('gamification.points_to_give_medal')"
			:description="$t('gamification.points_to_give_medal_tip')"
			:validation="{ required: true, numeric: true, min: 1 }"
			:preventNonNumeric="true"
			:preventDots="true"
			:preventHyphens="true"
			:preventLetters="true"
			:preventSpaces="true"
			type="number"
			min="1"
			@input="handlePointsInput"
			:value="points"
			:skeleton="skeleton"
		/>
	</div>
</template>

<script>
import EmblemInput from "./EmblemInput.vue";

export default {
	components: {
		EmblemInput,
	},
	props: {
		medal: { type: Object, default: () => ({}) },
		skeleton: { type: Boolean, default: false },
	},
	data() {
		return {
			emblem: null,
			name: "",
			points: "",
			id: undefined,
		};
	},
	watch: {
		medal: {
			handler() {
				this.emblem = this.medal.emblem;
				this.name = this.medal.name;
				this.points = this.medal.points;
				this.id = this.medal.id;
			},
			deep: true,
		},
		emblem() {
			this.emitChange();
		},
		name() {
			this.emitChange();
		},
		points() {
			this.emitChange();
		},
	},
	methods: {
		handleEmblemInput(newEmblem) {
			this.emblem = newEmblem;
		},
		handleNameInput(newName) {
			this.name = newName;
		},
		handlePointsInput(newPoints) {
			this.points = newPoints;
		},
		handleDelete() {
			this.emblem = null;
			this.name = "";
			this.points = "";
			this.id = null;
			this.emitChange();
			this.$emit("delete");
		},
		emitChange() {
			this.$emit("change", {
				id: this.id,
				emblem: this.emblem,
				name: this.name,
				points: this.points,
			});
		},
	},
};
</script>

<style lang="scss">
.medal-configuration {
	gap: 1rem;
}

@media (max-width: 768px) {
	.medal-configuration {
		flex-direction: column;
	}
}
</style>
