<template>
	<b-sidebar
		:id="id"
		sidebar-class="emblems-sidebar p-4"
		width="520px"
		backdrop
		right
		no-header
		no-footer
	>
		<div class="emblems-sidebar_header d-flex justify-content-between mb-4">
			<TextPlay
				color="var(--fontcolor)"
				size="lg"
				weight="semibold"
				:text="$t('gamification.select_a_medal')"
			/>

			<IconSax
				v-b-toggle="id"
				name="close-circle"
			/>
		</div>

		<div class="emblems-sidebar_body">
			<div
				class="emblems-sidebar_body_category mb-5"
				v-for="(category, index) in categories"
				:key="index"
			>
				<TextPlay
					color="var(--text-color)"
					size="sm"
					weight="bold"
					:text="category"
					class="mb-3 emblems-sidebar_body_category_title"
				/>
				<div class="emblems-sidebar_body_category_container">
					<div
						v-for="(emblem, index) in emblemsList"
						:key="index"
						v-show="emblem.category == category"
					>
						<Emblem
							@click="$emit('select', emblem)"
							:src="emblem.picture"
						/>
					</div>
				</div>
			</div>
		</div>
	</b-sidebar>
</template>

<script>
import { mapGetters } from "vuex";
import Emblem from "./Emblem.vue";

export default {
	components: {
		Emblem,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters({ emblemsList: "gamification/getEmblems" }),
		categories() {
			const categories = this.emblemsList.map(emblem => emblem.category) || [];
			return [...new Set(categories)];
		},
	},
};
</script>

<style lang="scss">
.emblems-sidebar {
	background-color: var(--backgroundcolor) !important;
	padding-right: 0 !important;
}

.b-sidebar-body {
	padding-right: 20px;
}
</style>

<style lang="scss" scoped>
.emblems-sidebar {
	&_body {
		&_category {
			&_container {
				display: grid;
				grid-template-columns: repeat(6, 1fr);
				column-gap: 8px;
				row-gap: 20px;
			}
		}
	}
}

@media (max-width: 768px) {
	.emblems-sidebar {
		&_body {
			&_category {
				&_container {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					column-gap: 8px;
					row-gap: 20px;
				}
			}
		}
	}
}
</style>
